import React, { useCallback, useContext, useEffect, useState } from 'react'

import { SettingsContext } from 'HOCs/withSettingsProvider'
import classNames from 'classnames'
import LinkTelegramNotification from 'components/LinkTelegramNotification/LinkTelegramNotification'
import { NotAuthContent } from 'components/NoContent'
import TariffItem from 'components/TariffItem/TariffItem'
import { MODAL_TYPES } from 'config/constants/constants'
import { useGetAccountSettings } from 'hooks/api/useAccount'
import {
  useBuyTariff,
  useGetTariffsData,
  useStopSubscription,
} from 'hooks/api/useTariff'
import useInvoiceModal from 'hooks/useInvoiceModal'
import { Trans, useTranslation } from 'react-i18next'
import withDashboardLayout from '../../HOCs/withDashboardLayout'
import { ModalContext } from '../../HOCs/withModalProvider'
import { formatDate } from '../../utils/formatDate'
import './Tariffs.scss'

function FreeAccessNotification() {
  const { t } = useTranslation()

  return (
    <div className="free-access-notification">
      <div className="notification-content">
        <h3>{t('free-access-notification')}</h3>
        <p>{t('free-access-subscribe')}</p>
        <a
          href="https://t.me/botfatherdev"
          target="_blank"
          rel="noopener noreferrer"
          className="telegram-link"
        >
          <img
            src="/telegram-icon.svg"
            alt="Telegram"
            className="telegram-icon"
          />
          @botfatherdev
        </a>
      </div>
    </div>
  )
}

function TariffStatus({ status }) {
  return (
    <div className="tariff-info__item">
      <div className="tariff-info__item__title">
        <Trans>Статус</Trans>
      </div>
      <div className={classNames('tariff-info__item__value', status)}>
        {status === 'active' ? (
          <Trans>🟢 Активний</Trans>
        ) : status === 'expired' ? (
          <Trans>🔴 Закінчився</Trans>
        ) : status === 'to_expire' ? (
          <Trans>⏳ Закінчується</Trans>
        ) : status === 'pending' ? (
          <Trans>💳 Очікується оплата</Trans>
        ) : (
          <Trans>🚫 Неактивний</Trans>
        )}
      </div>
    </div>
  )
}

function MyTariffInfo({
  tariff,
  billingCycle,
  userSubcription,
  currentStage,
  pendingTariffInfo,
  cancelSubscription,
  resumeSubscription,
}) {
  if (!userSubcription || !tariff) return null

  return (
    <>
      <div className="tariff-info">
        <div className="tariff-info__item header">
          <div className="tariff-info__item__title">
            <Trans>Ваш тариф</Trans>
          </div>
          <div className="tariff-info__item__value">{tariff.name}</div>
        </div>
        <TariffStatus status={userSubcription?.status} />

        <div className="tariff-info__item">
          <div className="tariff-info__item__title">
            <Trans>Цикл оплати</Trans>
          </div>
          <div className="tariff-info__item__value">
            ${currentStage?.price} /
            {billingCycle === 'weekly' ? (
              <Trans>тиждень</Trans>
            ) : (
              <Trans>рік</Trans>
            )}
          </div>
        </div>

        {userSubcription?.status === 'to_expire' && (
          <div className="tariff-info__item">
            <div className="tariff-info__item__title">
              {pendingTariffInfo ? (
                <Trans>Буде змінений</Trans>
              ) : (
                <Trans>Закінчується</Trans>
              )}
            </div>
            <div className="tariff-info__item__value">
              {formatDate(userSubcription?.end_date)}
            </div>
          </div>
        )}

        {userSubcription?.due_payment_date && (
          <div className="tariff-info__item">
            <div className="tariff-info__item__title">
              <Trans>Наступна оплата</Trans>
            </div>
            <div className="tariff-info__item__value">
              {formatDate(userSubcription?.due_payment_date)}
            </div>
          </div>
        )}
        {userSubcription?.status === 'active' && (
          <div className="tariff-info__item">
            <div className="tariff-info__item__title cancel-btn">
              <button
                className="cancel-btn"
                type="button"
                onClick={(event) =>
                  cancelSubscription(
                    event,
                    userSubcription?.tariff_id,
                    userSubcription?.billing_cycle
                  )
                }
              >
                <Trans>Скасувати підписку</Trans>
              </button>
            </div>
          </div>
        )}
        {userSubcription?.unused_days > 0 && (
          <div className="tariff-info__item">
            <div className="tariff-info__item__title">
              <Trans>Не використано днів</Trans>
            </div>
            <div className="tariff-info__item__value">
              {userSubcription?.unused_days}
            </div>
          </div>
        )}
        {userSubcription?.status === 'expired' &&
          userSubcription?.unused_days > 0 && (
          <div className="tariff-info__item">
            <div className="tariff-info__item__title resume-btn">
              <button
                className="resume-btn"
                type="button"
                onClick={(e) =>
                  resumeSubscription(
                    e,
                    userSubcription?.tariff_id,
                    userSubcription?.billing_cycle
                  )
                }
              >
                <Trans>Відновити підписку</Trans>
              </button>
            </div>
          </div>
        )}
      </div>

      {pendingTariffInfo && (
        <div className="tariff-info">
          <div className="tariff-info__item header">
            <div className="tariff-info__item__title">
              <Trans>Наступний тариф</Trans>
            </div>
            <div className="tariff-info__item__value">
              {pendingTariffInfo?.tariff?.name}
            </div>
          </div>
          <div className="tariff-info__item">
            <div className="tariff-info__item__title">
              <Trans>Цикл оплати</Trans>
            </div>
            <div className="tariff-info__item__value">
              ${pendingTariffInfo?.currentStage?.price} /
              {pendingTariffInfo?.billing_cycle === 'weekly' ? (
                <Trans>тиждень</Trans>
              ) : (
                <Trans>рік</Trans>
              )}
            </div>
          </div>
          {pendingTariffInfo?.unused_days > 0 && (
            <div className="tariff-info__item">
              <div className="tariff-info__item__title">
                <Trans>Не використано днів</Trans>
              </div>
              <div className="tariff-info__item__value">
                {pendingTariffInfo?.unused_days}
              </div>
            </div>
          )}
          {userSubcription?.status === 'to_expire' && (
            <div className="tariff-info__item">
              <div className="tariff-info__item__title cancel-btn">
                <button
                  className="cancel-btn"
                  type="button"
                  onClick={(event) =>
                    cancelSubscription(event, pendingTariffInfo?.id)
                  }
                >
                  <Trans>Скасувати підписку</Trans>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export function TariffsOptions({
  tariffs,
  accountData,
  selectedBillingCycle,
  setSelectedBillingCycle,
  handleBuyTariff,
  handleStopSubscription,
  userPricingStages,
  mySubscription,
  coupon,
}) {
  return (
    <div className="tariffs-options">
      <div className="_wrap">
        <div className="billing-cycle-switch">
          <button
            type="button"
            className={classNames('weekly', {
              active: selectedBillingCycle === 'weekly',
            })}
            onClick={() => setSelectedBillingCycle('weekly')}
          >
            <Trans>Щотижнева</Trans>
          </button>

          <button
            type="button"
            className={classNames('yearly', {
              active: selectedBillingCycle === 'yearly',
            })}
            onClick={() => setSelectedBillingCycle('yearly')}
          >
            <Trans>Щорічна</Trans>
          </button>
        </div>
      </div>
      <div className="option-list">
        {tariffs.map((tariff, index) => {
          const isActive =
            tariff.id === accountData?.tariff?.id &&
            selectedBillingCycle === mySubscription?.billing_cycle &&
            (mySubscription?.status === 'active' ||
              mySubscription?.status === 'to_expire')
          const disabledTariff = tariff.id < accountData?.tariff?.id
          const stages = tariff.pricingStages.filter(
            (stage) => stage.billing_cycle === selectedBillingCycle
          )
          const currentStage = userPricingStages?.find(
            (stage) =>
              stage.tariff_id === tariff.id &&
              stage.billing_cycle === selectedBillingCycle
          )
          const userSubcription = accountData?.subscriptions.find(
            (subscription) =>
              subscription.tariff_id === tariff.id &&
              subscription.billing_cycle === selectedBillingCycle
          )
          return (
            <TariffItem
              key={tariff.id}
              isActiveTariff={isActive}
              description={tariff.description}
              canDowngrade={tariff.id < accountData?.tariff?.id}
              name={tariff.name}
              price={tariff.price}
              tariffKey={tariff.key}
              number={index + 1}
              options={tariff.items}
              hasBuyButton={!isActive && !disabledTariff}
              handleBuy={(event) =>
                handleBuyTariff(
                  event,
                  tariff.id,
                  selectedBillingCycle,
                  coupon?.id
                )
              }
              handleStopSubscription={(event) =>
                handleStopSubscription(event, userSubcription?.id)
              }
              handleDowngrade={(event) =>
                handleBuyTariff(
                  event,
                  tariff.id,
                  selectedBillingCycle,
                  coupon?.id
                )
              }
              stages={stages}
              currentStage={currentStage}
              billingCycle={selectedBillingCycle}
              userSubcription={userSubcription}
              isPending={
                accountData?.nextSubscription?.tariff_id === tariff.id &&
                accountData?.nextSubscription?.billing_cycle ===
                  selectedBillingCycle
              }
              pendingDate={mySubscription?.end_date}
            />
          )
        })}
      </div>
    </div>
  )
}

function Tariffs({ isAuth }) {
  const { accountData } = useContext(SettingsContext)
  const { onOpen: openModal, onClose: closeModal } = useContext(ModalContext)

  const { data, refetch: refetchTariffs } = useGetTariffsData()
  const [selectedBillingCycle, setSelectedBillingCycle] = useState('weekly')
  const { data: tariffs, coupon, userPricingStages } = data ?? {}
  const hasTelegram = Boolean(accountData?.telegram_id)
  const { refetch: refetchAccountData } = useGetAccountSettings(true)

  const {
    mutate: buyTariff,
    isSuccess,
    data: purchaseLink,
    isLoading: buyActionLoading,
  } = useBuyTariff()
  const {
    mutate: stopSubscription,
    isSuccess: isStopSuccess,
    isLoading: stopActionLoading,
  } = useStopSubscription()

  const mySubscription = accountData?.subscriptions?.reduce(
    (selected, subscription) => {
      const { status } = subscription

      // If we already found a preferred subscription, keep it
      if (
        selected &&
        (selected.status === 'active' || selected.status === 'to_expire')
      ) {
        return selected
      }

      // If this subscription has a preferred status, choose it
      if (status === 'active' || status === 'to_expire') {
        return subscription
      }

      // If there is no selected subscription yet or the current subscription has a higher ID, choose it
      if (
        !selected ||
        (subscription.id > selected.id && status !== 'pending')
      ) {
        return subscription
      }

      // Otherwise, keep the current subscription as a fallback
      return selected
    },
    null
  )

  useInvoiceModal(
    purchaseLink?.data?.url,
    isSuccess && purchaseLink?.data?.url,
    () => {
      refetchAccountData()
      refetchTariffs()
    }
  )

  const handleBuyTariff = useCallback(
    (event, id, billingCycle, coupon) => {
      const data = {
        tariff_id: id,
        coupon_id: coupon,
        billing_cycle: billingCycle,
      }
      if (!coupon) delete data.coupon_id
      openModal(event, MODAL_TYPES.loader)
      buyTariff(data)
    },
    [buyTariff]
  )

  const handleStopSubscription = useCallback(
    (event, subscriptionId) => {
      openModal(event, MODAL_TYPES.loader)
      stopSubscription({
        subscription_id: subscriptionId,
      })
      refetchTariffs()
      refetchAccountData()
    },
    [stopSubscription]
  )

  useEffect(() => {
    if (isSuccess || isStopSuccess) {
      refetchTariffs()
      refetchAccountData()
    }
  }, [isSuccess])

  useEffect(() => {
    if (purchaseLink?.data?.url) {
      return
    }
    if (stopActionLoading === false || buyActionLoading === false) {
      closeModal()
    }
  }, [stopActionLoading, buyActionLoading, purchaseLink])

  return (
    <div className="tariffs-page">
      <FreeAccessNotification />
      {!isAuth ? (
        <NotAuthContent />
      ) : (
        <>
          {!hasTelegram && <LinkTelegramNotification />}
          <div className="tariffs-page__content">
            {tariffs && accountData && (
              <section className="tariff">
                <div className="tariff__container container">
                  <div className="tariffs-explanation__container container">
                    <div className="tariffs-explanation__text article-desc">
                      <h1>
                        <Trans i18nKey="tariffs-title" />
                      </h1>
                      <p>
                        <Trans i18nKey="tariffs-text-1" />
                      </p>
                      <p>
                        <Trans i18nKey="tariffs-text-2" />
                      </p>
                      <p>
                        <Trans i18nKey="tariffs-text-3" />
                      </p>
                      {mySubscription?.tariff && (
                        <MyTariffInfo
                          tariff={mySubscription.tariff}
                          billingCycle={mySubscription?.billing_cycle}
                          userSubcription={mySubscription}
                          currentStage={userPricingStages.find(
                            (stage) =>
                              stage.tariff_id === mySubscription.tariff?.id &&
                              stage.billing_cycle ===
                                mySubscription?.billing_cycle
                          )}
                          pendingTariffInfo={accountData.nextSubscription}
                          cancelSubscription={handleStopSubscription}
                          resumeSubscription={handleBuyTariff}
                        />
                      )}

                      <h2>
                        <Trans i18nKey="tariffs-title-2" />
                      </h2>
                      <p>
                        <Trans i18nKey="tariffs-text-4" />
                        <a href={'https://botfather.dev/balance'}>
                          <Trans i18nKey="tariffs-text-5" />
                        </a>
                      </p>
                      <p>
                        <Trans
                          i18nKey="purchaseInstruction-6"
                          components={{ strong: <strong /> }}
                        />
                      </p>
                    </div>
                    <TariffsOptions
                      tariffs={tariffs}
                      accountData={accountData}
                      selectedBillingCycle={selectedBillingCycle}
                      setSelectedBillingCycle={setSelectedBillingCycle}
                      handleBuyTariff={handleBuyTariff}
                      handleStopSubscription={handleStopSubscription}
                      userPricingStages={userPricingStages}
                      mySubscription={mySubscription}
                      coupon={coupon}
                    />
                  </div>
                </div>
              </section>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default withDashboardLayout()(Tariffs)
