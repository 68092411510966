import { SettingsContext } from 'HOCs/withSettingsProvider'
import classnames from 'classnames'
import Icon from 'components/Icons/Icon'
import { articleMarkdown } from 'config/markdownConfig'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import useStreamChatCompletion from 'hooks/api/useChatGPT'
import useToastNotification from 'hooks/useToastNotification'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import remarkToc from 'remark-toc'
import './ChatWidget.scss'

// Custom Markdown Renderer Component
const SafeMarkdown = ({ children }) => {
  return (
    <ReactMarkdown
      components={articleMarkdown}
      remarkPlugins={[remarkToc]}
      rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
    >
      {children}
    </ReactMarkdown>
  )
}

const ChatWidget = () => {
  const [isActive, setIsActive] = useState(false)
  const [newMessage, setNewMessage] = useState('')
  const [isComposing, setIsComposing] = useState(false)
  const inputRef = useRef(null)
  const messagesEndRef = useRef(null)
  const chatContentRef = useRef(null)
  const { t } = useTranslation()
  const { accountData } = useContext(SettingsContext)
  const { notify, notifyStatus } = useToastNotification()
  const {
    messages,
    sendUserMessage,
    loading: isWriting,
    clear: clearMessages,
  } = useStreamChatCompletion(Number(accountData?.telegram_id))

  // Add isMobile check
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Auto-resize textarea
  const adjustTextareaHeight = (textarea) => {
    if (!textarea) return
    textarea.style.height = 'auto'
    textarea.style.height = Math.min(textarea.scrollHeight, 150) + 'px'
  }

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus()
      adjustTextareaHeight(inputRef.current)
    }
  }, [isActive, newMessage])

  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isActive &&
        !event.target.closest('.chat-widget') &&
        !event.target.closest('.chat-toggle')
      ) {
        setIsActive(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isActive])

  // Handle escape key
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isActive) {
        setIsActive(false)
      }
    }

    document.addEventListener('keydown', handleEscape)
    return () => document.removeEventListener('keydown', handleEscape)
  }, [isActive])

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (isActive) {
      scrollToBottom()
    }
  }, [messages, isActive])

  const sendMessage = async () => {
    if (accountData?.tariff_id < 9) {
      notify(
        t('You need to buy a tariff to use this feature'),
        notifyStatus.error
      )
      return
    }

    const trimmedMessage = newMessage.trim()
    if (!trimmedMessage || isWriting) return

    setNewMessage('')
    if (inputRef.current) {
      inputRef.current.style.height = '48px'
      inputRef.current.focus()
    }

    await sendUserMessage(trimmedMessage)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isComposing) {
      e.preventDefault()
      sendMessage()
    }
  }

  const clearHistory = () => {
    if (window.confirm(t('Are you sure you want to clear the chat history?'))) {
      clearMessages()
      notify(t('Chat history cleared'), notifyStatus.success)
    }
  }

  return (
    <>
      <button
        className={classnames('chat-toggle', {
          'chat-toggle__active': isActive,
        })}
        onClick={() => setIsActive(!isActive)}
        aria-label={isActive ? t('Close chat') : t('Open chat')}
      >
        <Icon ai_chat />
        <span>{isActive ? t('Close') : t('ai-chat-button')}</span>
      </button>

      <div
        className={classnames('chat-widget', { active: isActive })}
        style={{ right: !isMobile ? (isActive ? '24px' : '-100%') : 0 }}
        role="dialog"
        aria-label={t('Chat dialog')}
      >
        <div className="chat-header">
          {isMobile && (
            <button
              className="mobile-back"
              onClick={() => setIsActive(false)}
              aria-label={t('Close chat')}
            >
              <Icon arrow_left />
            </button>
          )}
          <span className="header-text">
            <Icon ai_chat />
            <Trans>ai-chat-header</Trans>
          </span>
          <button
            className="clear-history"
            onClick={clearHistory}
            aria-label={t('Clear chat history')}
          >
            <Icon bin />
          </button>
        </div>

        <div className="chat-content" ref={chatContentRef}>
          <div className="messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${message.role}`}
                role={message.role === 'assistant' ? 'complementary' : 'note'}
              >
                {typeof message.content === 'string' ? (
                  <SafeMarkdown>{message.content}</SafeMarkdown>
                ) : (
                  message.content
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <div className="input-box">
          <div className="input-area">
            <textarea
              ref={inputRef}
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value)
                adjustTextareaHeight(e.target)
              }}
              onKeyDown={handleKeyDown}
              onCompositionStart={() => setIsComposing(true)}
              onCompositionEnd={() => setIsComposing(false)}
              placeholder={t('Type a message...')}
              disabled={isWriting}
              rows={1}
              aria-label={t('Message input')}
            />
          </div>

          <button
            onClick={sendMessage}
            disabled={!newMessage.trim() || isWriting}
            aria-label={t('Send message')}
          >
            {isWriting ? t('Thinking...') : t('Send')}
          </button>

          <div className="footer-description">
            <p>
              <b>Shift + Enter</b> - {t('New line')}
            </p>
            <p>
              <b>Esc</b> - {t('Close')}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatWidget
