import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom'
import rehypeHighlight from 'rehype-highlight'
import remarkToc from 'remark-toc'

import { NotAuthContent, NotAvailableContent } from 'components/NoContent'
import LessonSlider from './LessonSlider'
import TestFrom from './TestForm'

import withDashboardLayout from 'HOCs/withDashboardLayout'
import ChatWidget from 'components/ChatWidget/ChatWidget'
import Select from 'components/Select/Select'
import languageLabels from 'config/constants/languageLabels'
import { status as lessonStatus } from 'config/constants/lessonStatus'
import ROUTES from 'config/constants/routes'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { lessonVideoUrl } from 'config/videoUrl'
import { useGetLesson, useGetLessonsById } from 'hooks/api/useLessons'
import { Trans, useTranslation } from 'react-i18next'

function Lesson({ isAuth }) {
  const { slug } = useParams()
  const { data } = useGetLesson(slug)
  const {
    data: lesson,
    nextLessonSlug,
    previousLessonSlug,
    status,
  } = data ?? {}
  const { data: lessonsData, refetch: lessonsRefetch } = useGetLessonsById(
    lesson?.category_id
  )
  const { t, i18n } = useTranslation()
  const [lessons, setLessons] = useState(lessonsData)
  const [lessonsRefetchOnDemand, setLessonsRefetchOnDemand] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [lessonUrl, setLessonUrl] = useState('')

  useEffect(() => {
    if (lessonsRefetchOnDemand) {
      lessonsRefetch()
      setLessonsRefetchOnDemand(false)
    }
    setLessons(lessonsData)
  }, [lessonsData, lessonsRefetchOnDemand])
  const refetchLessons = () => {
    setLessonsRefetchOnDemand(true)
  }

  const testingRequired = status && status === lessonStatus.testingRequired
  const purchaseRequired = status && status === lessonStatus.purchaseRequired

  const nextLessonLink = `${ROUTES.lessonDetail}/${nextLessonSlug}`
  const previousLessonLink = `${ROUTES.lessonDetail}/${previousLessonSlug}`

  const { REACT_APP_API_URL } = process.env

  const [dubbedVideoOptions, setDubbedVideoOptions] = useState([])

  const [subtitlesTracks, setSubtitlesTracks] = useState([])

  useEffect(() => {
    // Update lesson URL only if selectedLanguage changes and is not an empty string
    const lessonId = lesson?.id
    if (lessonId) {
      const url = lessonVideoUrl(lessonId, selectedLanguage || '')
      setLessonUrl(url)
    }
  }, [lesson?.id, selectedLanguage])

  useEffect(() => {
    const availableLanguages =
      lesson?.dubbedVideos?.map((dubbedVideo) => dubbedVideo.language) || []
    setDubbedVideoOptions([
      { label: t('За замовчуванням'), value: '' },
      ...availableLanguages.map((languageCode) => {
        const languageLabel = languageLabels.find(
          (label) => label.code === languageCode
        )
        return {
          label: languageLabel ? languageLabel.name : languageCode,
          value: languageCode,
        }
      }),
    ])
    if (
      availableLanguages.includes(i18n.language) &&
      selectedLanguage === null
    ) {
      setSelectedLanguage(i18n.language)
    }
  }, [lesson?.dubbedVideos])

  useEffect(() => {
    // Update subtitle tracks based on the selected language
    const subtitleTracks =
      lesson?.subtitles?.map((subtitle) => ({
        kind: 'subtitles',
        src: `${REACT_APP_API_URL}${subtitle.subtitle_file_url}`,
        srcLang: subtitle.language,
        label:
          languageLabels.find((label) => label.code === subtitle.language)
            ?.name || subtitle.language,
        default: subtitle.language === selectedLanguage,
      })) || []
    setSubtitlesTracks(subtitleTracks)
  }, [lesson?.subtitles, selectedLanguage])

  const handleSelectLanguage = (value) => {
    setSelectedLanguage(value)
  }

  switch (true) {
  case !isAuth:
    return <NotAuthContent />
  case purchaseRequired:
    return <NotAvailableContent />
  case isAuth:
    return (
      <>
        {data && lesson && (
          <div className="details-lessons">
            <div className="details-lessons__container container">
              <div className="details-lessons__title title">
                <h2>{lesson.name}</h2>
              </div>
              {testingRequired && (
                <div className="details-lessons_blocked-message">
                  <Trans i18nKey="lesson-testing-required" />
                </div>
              )}

              <div className="wrap">
                <div className="video">
                  {testingRequired ? (
                    <img
                      src={`${REACT_APP_API_URL}${data.data.preview_image}`}
                      alt={lesson.name}
                    />
                  ) : (
                    <div className="video-wrapper">
                      <ReactPlayer
                        url={lessonUrl}
                        controls={true}
                        width="100%"
                        height="100%"
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        config={{
                          file: {
                            attributes: {
                              onContextMenu: (e) => e.preventDefault(),
                              controlsList: 'nodownload',
                            },
                            tracks: subtitlesTracks,
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="btns-wrap">
                  <Link
                    to={previousLessonLink}
                    className={classNames('', {
                      disabled: !previousLessonSlug,
                    })}
                  >
                    <span className="btn-arrow btn-arrow_left pointer-events_none" />
                    <Trans i18nKey="lesson-previous-lesson" />
                  </Link>
                  <Link
                    to={nextLessonLink}
                    className={classNames('', { disabled: !nextLessonSlug })}
                  >
                    <Trans i18nKey="lesson-next-lesson" />
                    <span className="btn-arrow btn-arrow_right pointer-events_none" />
                  </Link>
                </div>
                {dubbedVideoOptions?.length > 1 && (
                  <div className="lesson-dubbed-video-select">
                    <h3>{t('lesson-dubbed-options')}</h3>
                    <Select
                      list={dubbedVideoOptions}
                      defaultItem={
                        dubbedVideoOptions.find(
                          (item) => item.value === selectedLanguage
                        ) || dubbedVideoOptions[0]
                      }
                      onSelect={handleSelectLanguage}
                    />
                  </div>
                )}
                <div className="lesson-desc">
                  <ReactMarkdown
                    remarkPlugins={[remarkToc]}
                    rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
                    children={lesson.description}
                    linkTarget={'_blank'}
                  />
                </div>
                {lesson.questions && lesson.questions.length > 0 && (
                  <TestFrom
                    tests={lesson.questions}
                    lessonId={lesson.id}
                    nextLessonSlug={nextLessonSlug}
                    setLessons={setLessons}
                    onTestPassed={refetchLessons}
                  />
                )}
                {lessons && lessons.length > 5 && (
                  <LessonSlider
                    data={lessons}
                    currentLessonId={data.data.id}
                  />
                )}
              </div>

              <ChatWidget />
            </div>
          </div>
        )}
      </>
    )
  default:
    return null
  }
}

export default withDashboardLayout()(Lesson)
