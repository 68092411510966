import React, { memo } from 'react'
import Marquee from 'react-fast-marquee'
import useWindowDimensions from 'hooks/useWindowDimension'
import './Banner.scss'

const Banner = memo(({ title, slogan, link }) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 768

  return (
    <>
      <a
        href={link}
        className="news-strip"
        target="_blank"
        rel="noreferrer"
        aria-label={`${slogan} - ${title}`}
      >
        <span className="news-strip__duplicate-text">{slogan}</span>

        {!isMobile && (
          <Marquee
            className="news-strip__text"
            gradient={false}
            speed={40}
            delay={2}
          >
            <p>{title}</p>
          </Marquee>
        )}

        <span className="news-strip__duplicate-text news-strip__right-wrap">
          {slogan}
        </span>
      </a>
    </>
  )
})

Banner.displayName = 'Banner'

export default Banner
