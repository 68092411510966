import React, { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { FreeMode, Mousewheel, Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'

import ROUTES from 'config/constants/routes'
import Icon from 'components/Icons/Icon'

const SliderItem = memo(
  ({ name, image, id, slug, hasAccess, currentLessonId, index, total }) => {
    const imageUrl = `${process.env.REACT_APP_API_URL}${image}`
    const isCurrentLesson = currentLessonId === id

    const renderProgressBar = () => (
      <div className="progress-bar">
        <div
          className="progress-bar__fill"
          style={{ width: `${((index + 1) / total) * 100}%` }}
        />
      </div>
    )

    const renderLessonNumber = () => (
      <div className="lesson-number">
        <span className="lesson-number__current">
          {String(index + 1).padStart(2, '0')}
        </span>
        <span className="lesson-number__total">
          /{String(total).padStart(2, '0')}
        </span>
      </div>
    )

    switch (true) {
    case hasAccess:
      return (
        <Link
          to={`${ROUTES.lessonDetail}/${slug}`}
          className={`link ${isCurrentLesson ? 'isCurrent' : ''} ${
            index < currentLessonId ? 'completed' : ''
          }`}
        >
          <div className="img-wrap">
            <img
              src={imageUrl}
              width="193"
              height="108"
              alt={name}
              loading="lazy"
            />
            {isCurrentLesson && (
              <div className="current-indicator">
                <Icon play />
              </div>
            )}
            {index < currentLessonId && (
              <div className="completed-indicator">
                <Icon check />
              </div>
            )}
            {renderProgressBar()}
          </div>
          <div className="lesson-info">
            {renderLessonNumber()}
            <h3 className="lesson-title">{name}</h3>
          </div>
        </Link>
      )
    case !hasAccess:
    default:
      return (
        <div className="link blocked">
          <div className="img-wrap">
            <div className="blocked-icon">
              <Icon locked />
            </div>
            <img
              src={imageUrl}
              width="193"
              height="108"
              alt={name}
              loading="lazy"
            />
            {renderProgressBar()}
          </div>
          <div className="lesson-info">
            {renderLessonNumber()}
            <h3 className="lesson-title">{name}</h3>
          </div>
        </div>
      )
    }
  }
)

const LessonSlider = memo(({ data, currentLessonId }) => {
  const initialSlideIndex = useMemo(() => {
    const currentLesson = data.find((lesson) => lesson.id === currentLessonId)
    return data.indexOf(currentLesson)
  }, [data, currentLessonId])

  return (
    <div className="slider-container">
      <div className="slider-header">
        <div className="progress">
          <div
            className="progress__bar"
            style={{
              width: `${((initialSlideIndex + 1) / data.length) * 100}%`,
            }}
          />
          <span className="progress__text">
            {initialSlideIndex + 1}/{data.length}
          </span>
        </div>
      </div>
      <Swiper
        className="slider"
        slidesPerView="auto"
        direction="horizontal"
        centeredSlides={false}
        loop={false}
        spaceBetween={16}
        initialSlide={initialSlideIndex}
        breakpoints={{
          320: {
            slidesPerView: 1.2,
            spaceBetween: 12,
            centeredSlides: false,
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 12,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 16,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
            centeredSlides: true,
          },
          1541: {
            slidesPerView: 4,
            spaceBetween: 20,
            direction: 'vertical',
            centeredSlides: false,
          },
        }}
        freeMode={{
          enabled: true,
          sticky: false,
          momentumBounce: false,
        }}
        scrollbar={{
          el: '.swiper-scrollbar',
          hide: false,
          draggable: true,
          dragSize: 100,
        }}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 1,
        }}
        navigation={{
          nextEl: '.slider-next',
          prevEl: '.slider-prev',
        }}
        modules={[Navigation, FreeMode, Scrollbar, Mousewheel]}
      >
        {data.map((lesson, index) => (
          <SwiperSlide key={lesson.id}>
            <SliderItem
              hasAccess={lesson.access}
              id={lesson.id}
              slug={lesson.slug}
              name={lesson.name}
              image={lesson.preview_image}
              currentLessonId={currentLessonId}
              index={index}
              total={data.length}
            />
          </SwiperSlide>
        ))}
        <div className="swiper-scrollbar"></div>
      </Swiper>
      <div className="slider-navigation">
        <button className="slider-prev" aria-label="Previous lesson">
          <Icon arrowLeft />
        </button>
        <button className="slider-next" aria-label="Next lesson">
          <Icon arrowRight />
        </button>
      </div>
    </div>
  )
})

export default LessonSlider
